@media only screen { 
    #bodyContainer, #forceLight:checked ~ #bodyContainer {
        background-color: #067A76;
        color-scheme: light;
    }

    header nav, #forceLight:checked ~ #bodyContainer header nav {
        background-color: #81E5EF;
    }

    main, dialog, #forceLight:checked ~ #bodyContainer main, #forceLight:checked ~ #bodyContainer dialog {
        background-color: #E0FCFF;
    }

    #forceDark:checked ~ #bodyContainer {
        background-color: #013331;
        color-scheme: dark;
    }

    #forceDark:checked ~ #bodyContainer header nav {
        background-color: #1f494e;
    }

    #forceDark:checked ~ #bodyContainer main, #forceDark:checked ~ #bodyContainer dialog {
        background-color: #002226;
    }
}
@media only screen and (prefers-color-scheme: dark) {
    #bodyContainer {
        background-color: #013331;
        color-scheme: dark;
    }

    header nav {
        background-color: #1f494e;
    }

    main, dialog {
        background-color: #002226;
    }
}